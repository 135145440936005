import { useEffect, useState } from "react";
import NextHead from "next/head";
import NextApp from "next/app";
import { Provider } from "react-intl-redux";
import getConfig from "next/config";
import { useRouter } from "next/router";
import NextNProgress from "nextjs-progressbar";
import NProgress from "nprogress";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Layout from "./components/layout/layout";
import {
  ssoLogin,
  catchRefererKey,
  checkAndRefreshToken,
  logoutSession,
} from "@tw/customer-identity-cra/lib/common/utils/login";
import { getAcApiUrl } from "@tw/shared-ui-components/lib/utils/helper"
import getOrCreateStore from "../redux/get-or-create-store";
import "./components/css/app.css";
import { PROTECTED_REGEX_ROUTES } from "../utils/Constants/Data";
import {
  getUserInfoFromCookie,
  redirectToLogin,
  isTokenValid,
} from "../utils/auth/helper";
import "../utils/auth/axios-interceptor";
import Tutorial from "./components/tutorial/tutorial";
function App(props) {
  const { Component, initialStateOrStore, pageProps } = props;
  const router = useRouter();

  const [routerAsPath, setRouterAsPath] = useState(null);

  const {
    layout = {},
    reduxModules: pageReduxModules = [],
    pageId,
  } = Component;
  useEffect(() => {
    setRouterAsPath(router?.asPath);
  }, [router]);

  useEffect(() => {
    checkForProtectedRoute();
  }, [routerAsPath]);

  const checkForProtectedRoute = () => {
    console.log("[app][checkForProtectedRoute] >> ", routerAsPath);

    const loggedInUserInfo = getUserInfoFromCookie() || {};
    const { accessToken, ada, role } = loggedInUserInfo;
    const tokenValidity = isTokenValid(accessToken);
    if (!accessToken || !ada || !role || !tokenValidity) {
      const isRoutePathProtected = PROTECTED_REGEX_ROUTES.some((regexRoute) =>
        regexRoute.test(routerAsPath)
      );

      if (isRoutePathProtected) {
        logoutSession();
        redirectToLogin("p");
      }
    }
  };

  // On SSR, `initialStateOrStore` will be the store. On the first client render, it will be the
  // initial state which will be used to create the client store. On subsequent client renders,
  // `initialStateOrStore` will be the singleton store.
  const store =
    initialStateOrStore.dispatch && initialStateOrStore.getState
      ? initialStateOrStore
      : getOrCreateStore(initialStateOrStore, pageReduxModules);

  const nextconfig = getConfig();
  if (typeof window !== "undefined") {
    window.appConfig = {
      ...nextconfig.publicRuntimeConfig,
    };
    window.useRouter = useRouter;
    window.useParams = () => useRouter().query;

    // for turn off progress filter/screen purpose.
    // Please use window.NProgress.done();
    window.NProgress = NProgress;

    // To perform ssoLogin
    ssoLogin();

    // To refresh accessToken, if refreshToken is expired,
    // it will proceed to logout and redirect to login
    checkAndRefreshToken(PROTECTED_REGEX_ROUTES);

    // To catch referrer key for shared-links
    catchRefererKey();
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdMwjUfAAAAABu-VGtB9VdGGOWwPtOPCK3wBoV3">
      <Provider store={store}>
        {
          // switchStatus?.enabled ? <div dangerouslySetInnerHTML={{ __html: switchStatus?.content }}></div>
          //   :
          //   <Layout>
          //     <NextHead>
          //       {/* <title>安麗（台灣）網路商城</title> */}
          //       <link rel="icon" href="/favicon.ico" />
          //       <meta content="yes" name="apple-mobile-web-app-capable" />
          //       <meta
          //         name="viewport"
          //         content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          //       />
          //     </NextHead>
          //     <Component {...pageProps} />
          //     <NextNProgress color="#38539a" />
          //     <Tutorial pageId={pageId} />
          //   </Layout>
        }
        <Layout>
          <NextHead>
            {/* <title>安麗（台灣）網路商城</title> */}
            <link rel="icon" href="/favicon.ico" />
            <meta content="yes" name="apple-mobile-web-app-capable" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </NextHead>
          <Component {...pageProps} />
          <NextNProgress color="#38539a" />
          <Tutorial pageId={pageId} />
        </Layout>
      </Provider>
      {/* To hide the Google reCaptcha v3 badge */}
      <style>
        {
          "\
        .grecaptcha-badge{\
          visibility: hidden;\
        }\
        "
        }
      </style>
    </GoogleReCaptchaProvider>
  );
}

// `App.getInitialProps` is executed on the server during SSR and it is NOT executed on the first
// client render. All subsequent client renders will execute it.
App.getInitialProps = async (appContext) => {
  const { Component, ctx } = appContext;
  const { reduxModules: pageReduxModules } = Component;

  // On SSR, this will create a store. On client renders (except for the first one), this will
  // retrieve the existing store, which will then be passed through to `App`.
  ctx.store = getOrCreateStore();

  // The `toJSON` method is executed when the return value of this function is serialized by next
  // and set on the html for hydration on the client.
  ctx.store.toJSON = () => ctx.store.getState();

  // On the server, set these immediately as there's no previous modules to remove
  if (typeof window === "undefined") {
    if (pageReduxModules) {
      ctx.store.addPageModules(pageReduxModules);
    }

    // To get login info on initial req in server side
    // console.log("cookie in _app", ctx.req.headers.cookie);
    if (ctx.req?.headers?.cookie) {
      process.loggedInUserInfo = getUserInfoFromCookie(
        ctx.req?.headers?.cookie
      );
    } else {
      process.loggedInUserInfo = undefined;
    }

    // To store full route of request to be used in canonical tag for seo
    process.fullRoute = `https://${ctx.req?.headers?.host}${ctx.req?.url}`;
    process.origin = `https://${ctx.req?.headers?.host}`;
  }

  let appProps = {};

  if (typeof window !== "undefined" && pageReduxModules?.length > 0) {
    //Bryan (20211112) - handle if the page is CSR
    if (pageReduxModules[0].id !== ctx.store.currentModule) {
      ctx.store.removePageModules();

      if (pageReduxModules) {
        ctx.store.addPageModules(pageReduxModules);
      }
    }
  }
  // const res = await fetch(`${getAcApiUrl()}/anon/getMaintenanceStatus`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   }
  // })
  // const posts = await res?.json()
  try {
    appProps = await NextApp.getInitialProps(appContext);
  } catch (error) {
    console.log("_app.js: error", error);
  }
  return {
    // switchStatus: posts,
    ...appProps,
    initialStateOrStore: ctx.store,
  };
};

export default App;
